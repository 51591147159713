import _ from 'lodash';
import sha1 from 'sha1';
import { getItemType } from '../lib/commonUtils';
import { BaseEvent } from './events';

export const stepNameFormat = (stepName: string) => {
  const name = (stepName || '').replace('Step', '');
  return name.substring(0, 1).toLowerCase() + name.substring(1);
};

const DOMAINS = {
  authenticator: 'authenticator',
  submission: 'submission',
  contetnt: 'content',
  photoService: 'photoService',
};

const validateData = (methodName: string, dataKeys: string[]) => {
  const missing = BaseEvent.keysMissingFromDataObj(dataKeys);
  if (missing.length > 0) {
    const msg = `${methodName}, these params are missing: ${missing} , current data value: ${JSON.stringify(
      BaseEvent.dataObject,
    )}`;
    console.error(msg);
    throw new Error(msg);
  }
};

type GASelect = {
  step: string;
  object?: string;
  value: any;
  object2?: string;
  value2?: string;
  object3?: string;
  value3?: string;
};

type GASelectPhotoUpload = {
  value: 'none' | 'certificate' | 'photo' | 'photoAndCertificate';
  object2: string;
  value2: string;
  object3?: string;
  value3?: string;
  object4?: string;
  value4: boolean;
};

class GA {
  static stepView(
    step: string,
    object?: string,
    value?: string,
    object2?: string,
    value2?: string,
    object3?: string,
    value3?: string,
  ) {
    const itemType = getItemType();
    const stepFormat = stepNameFormat(step);
    const formatSepVew = stepFormat.includes(itemType) ? stepFormat : `${itemType} ${stepFormat}`;

    BaseEvent.open(
      [DOMAINS.submission, itemType, stepFormat],
      object,
      value,
      itemType === 'ring' ? object2 : undefined,
      itemType === 'ring' ? value2 : undefined,
      _.camelCase(`${itemType} SubmissionFunnel`),
      _.camelCase(`${formatSepVew} StepView`),
    );
  }

  static registrationStepView(stepName: string) {
    const itemType = getItemType();
    const stepFormat = stepNameFormat(stepName);
    const formatSepVew = stepFormat.includes(itemType) ? stepFormat : `${itemType} ${stepFormat}`;

    BaseEvent.open(
      [DOMAINS.authenticator, stepFormat],
      undefined,
      undefined,
      undefined,
      undefined,
      _.camelCase(`${itemType} SubmissionFunnel`),
      _.camelCase(`${formatSepVew} StepView`),
    );
  }

  static caratPopupView(step: string) {
    const itemType = getItemType();
    BaseEvent.open([DOMAINS.submission, itemType, stepNameFormat(step), 'caratDecimalPopup']);
  }

  static aiChatView() {
    BaseEvent.open(
      [DOMAINS.submission, 'SubmissionAi'],
      '',
      '',
      '',
      '',
      'ringSubmissionFunnel',
      'ringSubmissionAiStepView',
    );
  }

  static tooltipView(step: string) {
    const itemType = getItemType();
    BaseEvent.navigate([DOMAINS.submission, itemType, stepNameFormat(step)], 'tooltip');
  }

  static aiFocus() {
    BaseEvent.focus([DOMAINS.submission, 'SubmissionAi'], 'aiTextInputFocus');
  }

  static inputMethodSelected(step: string, value: string) {
    const itemType = getItemType();
    BaseEvent.select([DOMAINS.submission, itemType, stepNameFormat(step)], 'inputMethod', value);
  }

  static autoRejectionView(object3?: string, value3?: string) {
    const itemType = getItemType();
    BaseEvent.open(
      [DOMAINS.submission, itemType, 'autoRejection'],
      undefined,
      undefined,
      undefined,
      undefined,
      itemType === 'ring' ? object3 : undefined,
      itemType === 'ring' ? value3 : undefined,
    );
  }

  static successSubmitByPhotoUpload(step: string) {
    BaseEvent.success([DOMAINS.submission, getItemType(), stepNameFormat(step)]);
  }

  static cashForGold() {
    BaseEvent.navigate([DOMAINS.submission, getItemType(), 'autoRejection'], 'cashForGold');
  }

  static submitNewItem() {
    const itemType = getItemType();
    const itemCase = _.replace(`rejection${_.startCase(itemType)}CtaBtn`, ' ', '');

    BaseEvent.navigate(
      [DOMAINS.submission, itemType, 'autoRejection'],
      'ctaSubmission',
      itemCase,
      'destination',
      'jewleryType',
    );
  }

  static typeSelectionView() {
    BaseEvent.open([DOMAINS.submission, 'jewleryType']);
  }

  /// click events
  static previousClick(step: string) {
    const itemType = getItemType();
    BaseEvent.navigate([DOMAINS.submission, itemType, stepNameFormat(step)], 'back');
  }

  static select({ step, object, value, object2, value2, object3, value3 }: GASelect) {
    const itemType = getItemType();
    const stepFormat = stepNameFormat(step);
    const formatSepVew = stepFormat.includes(itemType) ? stepFormat : `${itemType} ${stepFormat}`;

    BaseEvent.select(
      [DOMAINS.submission, itemType, stepFormat],
      object || stepNameFormat(step),
      _.camelCase(value),
      object2,
      value2,
      _.camelCase(`${itemType} SubmissionFunnel`),
      _.camelCase(`${formatSepVew} StepNext`),
    );
  }

  static intercomClick(step: string) {
    const itemType = getItemType();
    BaseEvent.navigate([DOMAINS.submission, itemType, stepNameFormat(step)], 'intercom');
  }

  static chatNext() {
    BaseEvent.navigate(
      [DOMAINS.submission, 'submissionAi'],
      'submissionAiConfirmItemInfoNextBtn',
      '',
      '',
      '',
      'aiSubmissionFunnel',
      'submissionAiStepNext',
    );
  }

  static submitItemType(value: string) {
    BaseEvent.select([DOMAINS.submission, 'typeSelection'], 'itemType', value);
  }

  static registrationSuccess(
    object?: string,
    value?: string,
    object2?: string,
    value2?: string,
    object4?: string,
    value4?: string,
  ) {
    const itemType = getItemType();

    const dataKeys = [
      'userId',
      'userPhone',
      'userEmail',
      'userFullName',
      'emailSha1Encryption',
      'itemId',
    ];

    validateData('registrationSuccess', dataKeys);

    BaseEvent.success(
      [DOMAINS.authenticator, 'registration'],
      itemType === 'ring' ? object : undefined,
      itemType === 'ring' ? value : undefined,
      itemType === 'ring' ? object2 : undefined,
      itemType === 'ring' ? value2 : undefined,
      _.camelCase(`${itemType} SubmissionFunnel`),
      _.camelCase(`${itemType} registrationSuccessStep`),
      object4,
      value4,
    );
  }

  static submitNext(step: string, value: any, previousInput?: any, previousInputLocation?: string) {
    const itemType = getItemType();
    const location = [DOMAINS.submission, itemType, stepNameFormat(step)];
    if (previousInputLocation) {
      location.push(previousInputLocation);
    }
    const previousInputName = previousInput ? 'previousInput' : '';
    BaseEvent.input(location, stepNameFormat(step), value, previousInputName, previousInput);
  }

  static submitGIANumber(page: string, step: string, value: any) {
    BaseEvent.input([DOMAINS.submission, page, 'certificate'], stepNameFormat(step), value);
  }

  static submitCaratWeight(range: string, classification: string) {
    const itemType = getItemType();
    const stepFormat = 'carat';
    const formatSepVew = `${itemType} ${stepFormat}`;

    BaseEvent.select(
      [DOMAINS.submission, getItemType(), stepFormat],
      stepFormat,
      range,
      'stoneTypeClassification',
      classification,
      _.camelCase(`${itemType} SubmissionFunnel`),
      _.camelCase(`${formatSepVew} StepNext`),
    );
  }

  static submitFlowType(value: 'submitByForm' | 'submitByImage') {
    BaseEvent.select(
      [DOMAINS.submission, getItemType(), 'submissionFlowType'],
      'submissionFlowTypeBtns',
      value,
      undefined,
      undefined,
      'ringSubmissionFunnel',
      'flowTypeNext',
    );
  }

  static submitByImg(step: string, classification: string) {
    BaseEvent.select(
      [DOMAINS.submission, getItemType(), stepNameFormat(step)],
      'carat',
      'imageInput',
      'stoneTypeClassification',
      classification,
      'ringSubmissionFunnel',
      'submitByImgStepNext',
    );
  }

  static successfullPhotoUpload() {
    BaseEvent.success([DOMAINS.photoService, 'photoUpload', 'photoUploaded']);
  }

  static successfullCertificateUpload() {
    BaseEvent.success([DOMAINS.photoService, 'photoUpload', 'certificateUploaded']);
  }

  static continueSelectPhotoUpload({
    value,
    object2,
    value2,
    object3,
    value3,
    value4,
  }: GASelectPhotoUpload) {
    const itemType = getItemType();
    const stepFormat = 'photoUpload';

    BaseEvent.select(
      [DOMAINS.photoService, stepFormat],
      'nextBtn',
      value,
      object2,
      value2,
      _.camelCase(`${itemType} SubmissionFunnel`),
      _.camelCase(`${itemType} ${stepFormat}StepNext`),
      'mandatoryStep',
      value4,
    );
  }

  static continuePhotoUpload(value: 'none' | 'certificate' | 'photo' | 'photoAndCertificate') {
    BaseEvent.navigate([DOMAINS.photoService, 'photoUpload'], 'next', value);
  }

  static successfullGoogleRegister(isUserLogin: boolean) {
    if (isUserLogin) {
      BaseEvent.success([DOMAINS.authenticator, 'login', 'google']);
    } else {
      BaseEvent.success([DOMAINS.authenticator, 'registration', 'google']);
    }
  }

  static successfullItemSubmission() {
    BaseEvent.success([DOMAINS.submission, 'submitItem']);
  }

  static experimentEvent(experimentName: string, variantName: string) {
    BaseEvent.experiment({ experimentName, variantName });
  }

  static dataEvents = {
    itemSubmited: (data: { itemId: number; itemType: string }) => {
      BaseEvent.data(data);
    },
    userIdentified: (data: {
      userEmail: string;
      userFirstName: string;
      userLastName: string;
      userPhone: string;
      userId: number;
    }) => {
      BaseEvent.data({
        userPhone: data.userPhone,
        userEmail: data.userEmail,
        userId: data.userId,
        userFullName: `${data.userFirstName} ${data.userLastName}`,
        emailSha1Encryption: sha1(data.userEmail),
      });
    },
    postRegEstimation: (data: {
      estimationValue: number;
      valueCategory: string;
      submissionQualification: number;
      ml1Factor: number;
      preregScore: number;
      ml4Score: number;
    }) => {
      BaseEvent.data({ ...data });
    },
    hqPurchase: (data: { postregScore: number; estimationValue: number }) => {
      const dataKeys = ['userId'];
      validateData('hqPurchase', dataKeys);
      BaseEvent.data(data);
    },
    ml4Purchase: (data: { ml4ConversionValue: number }) => {
      BaseEvent.data(data);
    },
    firstQualifiedSubmission: (data: { projectedLeadValue: number }) => {
      const dataKeys = [
        'userId',
        'postregScore',
        'userPhone',
        'userEmail',
        'userFullName',
        'emailSha1Encryption',
        'itemId',
        'estimationValue',
      ];
      validateData('firstQualifiedSubmission', dataKeys);
      BaseEvent.data({
        projectedLeadValue: data.projectedLeadValue,
        itemsCount: 1,
        daysSinceUserCreated: 0,
      });
    },
    googleData: (data: {
      userEmail: string;
      userFirstName: string;
      userLastName: string;
      userPhone: string;
    }) => {
      BaseEvent.data(data);
    },
  };
}

export default GA;
