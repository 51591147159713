/* eslint no-param-reassign: ["error", { "props": false }] */

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import type { IStep } from '../components/wizard';
import { CertificateType, CLASSIFICATION } from '../data/ItemDetails';
import {
  dataUrlToFile,
  mapEventCertificateTypeToCertificate,
  mapEventSettingStyleToStyles,
  mapEventShapeToShape,
  mapEventStoneClarityToClarity,
  mapEventStoneColorToDiamondColor,
  mapEventStoneColorToGemstoneColor,
  mapEventStoneTypeToClassification,
} from '../lib/commonUtils';
import { redirectToNewItem } from '../lib/redirectUtils';
import {
  nextVisibleStepIndex,
  prevVisibleStepIndex,
  Vertex,
  VertexId,
  LabeledDirectedGraph,
} from '../lib/wizard';

import WorthyAPI from '../services/worthyAPI';
import type { RootState } from '../app/store';
import { generateEstimation } from '../lib/submitHelper';
import GA from '../data/GA';
import { isItemRejected } from '../validation/validations';
import { QUALIFICATION } from '../data/constants';
import { registerToPromotion } from '../lib/promotions';
import { CookieStore } from '../services/cookieStore';

const SLICE_NAME = 'submit';

export const POST_REG_SCORE_ADDITION = 0.01;
export const MAX_CARAT_FOR_GA = 7;

export const postregMedian = Number(process.env.REACT_APP_POSTREG_SCORE_MEDIAN) || 0.08;
export const estimationToEvaluation =
  Number(process.env.REACT_APP_ESTIMATION_TO_EVALUATION_FACTOR) || 0.6;
export const proceedToAuction = Number(process.env.REACT_APP_PROCEED_TO_AUCTION_PROBABILITY) || 0.8;
export const dealProbability = Number(process.env.REACT_APP_DEAL_PROBABILITY) || 0.8;
export const feesPercent = Number(process.env.REACT_APP_FEES_PERCENT) || 0.3;

export type TRegistration = {
  [index: string]: string;
};

export interface IRegistration {
  smsConsent?: boolean;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  activeFields?: string[];
}

export interface PhotoI {
  id: number;
  src: string | undefined;
  url: string;
  type: string;
}

export interface PhotoOldI {
  id: number;
  src: string | null;
}

const defaultPhotos: PhotoI[] = [];

const defaultPhotosOld: PhotoOldI[] = [
  {
    id: 1,
    src: null,
  },
  {
    id: 2,
    src: null,
  },
  {
    id: 3,
    src: null,
  },
  {
    id: 4,
    src: null,
  },
];

export type MessageRole = 'assistant' | 'user';
export interface Message {
  createdAtMs: number;
  content: string;
  data: Record<string, any> | null;
  role: MessageRole;
}

export interface SubmitState {
  type: string;
  setting: string;
  shape: string;
  style: string;
  diamondNumber: number;
  totalCarat: number;
  metalType: string;
  metalWeight: number;
  carat: number;
  caratRangeTo?: number;
  color: string;
  clarity: string;
  certificate: string;
  condition: string;
  customBrand: string;
  model: string;
  box: string;
  papers: string;
  bandMaterial: string;
  caseMaterial: string;
  gender: string;
  certificateNumber: string;
  caratClassification: CLASSIFICATION;
  brand: string;
  premiumBrand: boolean;
  photos: PhotoI[];
  measureByImg: boolean;
  photosOld: PhotoOldI[];
  secondarySubset: string;
  submissionId: string;
  wizard: {
    stepIndex: number;
    isFinal: boolean;
    stepName: string;
    loading: number;
    // wizard2:
    currentVertexId: VertexId | null;
    history: VertexId[];
  };
  registration: IRegistration;
  registerAPI: {
    loading: boolean;
    isError: boolean;
    errorMsg: string;
    userData: Record<string, any> | null;
    err: Record<string, any> | null;
  };
  submitAPI: {
    isError: boolean;
    errorMsg: string;
    bundleId: number;
    itemId: string;
    forcePhoneNumber: boolean;
    defaultEstimation: number;
    displayOptionalPhoneLabel: boolean;
    forcePhoto: boolean;
    itemAutoRejected: boolean;
    rejectReason: string;
    item: Record<string, any> | null;
    err: Record<string, any> | null;
    retryIsNeeded: boolean;
  };
  // eslint-disable-next-line @typescript-eslint/ban-types
  user: {
    id?: string;
    isLoggedIn?: boolean;
    phone?: string;
    userData: {
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      phone: string;
    };
  };
  estimationAPI: {
    estimationValue: number;
    ml1Factor: number;
    valueCategory: string;
    submissionQualification: number;
    preregScore: number;
    ml4PreregScore: number;
  };
  metaData: {
    activeFields: string[];
  };
  conversation: {
    publicItemId: string | null;
    itemData?: Record<string, any>;
    waiting: boolean;
    responseStarted: boolean;
    submit: boolean;
    messages: Message[];
  };
}

const initialSubmitValues = {
  setting: '',
  shape: '',
  carat: 0,
  caratRangeTo: undefined,
  style: '',
  diamondNumber: 0,
  totalCarat: 0,
  metalType: '',
  metalWeight: 0,
  color: '',
  clarity: '',
  caratClassification: CLASSIFICATION.NATURAL,
  certificate: CertificateType.None,
  condition: '',
  customBrand: '',
  model: '',
  box: '',
  papers: '',
  bandMaterial: '',
  caseMaterial: '',
  gender: '',
  certificateNumber: '',
  brand: '', // Empty string to avoid "other" as default
  premiumBrand: false,
  photos: defaultPhotos,
  photosOld: defaultPhotosOld,
  secondarySubset: '',
  measureByImg: false,
};

export const initialState: SubmitState = {
  ...initialSubmitValues,
  type: '',
  submissionId: uuidv4(),
  wizard: {
    stepIndex: 0,
    stepName: '',
    isFinal: false,
    loading: 0,
    // wizard2:
    currentVertexId: null,
    history: [],
  },
  registration: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  },
  registerAPI: {
    loading: false,
    isError: false,
    errorMsg: '',
    userData: null,
    err: null,
  },
  submitAPI: {
    isError: false,
    errorMsg: '',
    bundleId: 0,
    itemId: '',
    forcePhoneNumber: false,
    defaultEstimation: 0,
    displayOptionalPhoneLabel: false,
    forcePhoto: false,
    itemAutoRejected: false,
    rejectReason: '',
    item: null,
    err: null,
    retryIsNeeded: false,
  },
  user: {
    id: '',
    isLoggedIn: false,
    userData: {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    },
  },
  estimationAPI: {
    estimationValue: 0,
    ml1Factor: 0.1083,
    valueCategory: '',
    submissionQualification: 0,
    preregScore: 0.1083,
    ml4PreregScore: 0,
  },

  metaData: {
    activeFields: [],
  },

  conversation: {
    publicItemId: null,
    itemData: {},
    submit: false,
    waiting: false,
    responseStarted: false,
    messages: [],
  },
};

export type ItemInputsTypes = Pick<
  SubmitState,
  | 'type'
  | 'setting'
  | 'brand'
  | 'carat'
  | 'caratRangeTo'
  | 'certificate'
  | 'clarity'
  | 'shape'
  | 'color'
  | 'certificateNumber'
  | 'caratClassification'
  | 'photos'
  | 'measureByImg'
>;

export type EarringsItemInputsTypes = Pick<
  SubmitState,
  | 'style'
  | 'metalType'
  | 'metalWeight'
  | 'diamondNumber'
  | 'totalCarat'
  | 'shape'
  | 'carat'
  | 'certificate'
  | 'brand'
  | 'photos'
>;

const prepareImages = (images: PhotoI[]) => {
  const imagesToUpload = images.filter((image) => image.src);
  return Promise.all(imagesToUpload.map((image) => dataUrlToFile(image.src as string)));
};

async function submitImages(photos: PhotoI[], itemId: string) {
  let files: File[] = [];

  if (photos.length !== 0) {
    files = await prepareImages(photos);
  }

  if (files.length > 0) {
    try {
      const formData = new FormData();
      [...files].forEach((file, idx) => {
        formData.append(`file_${idx}`, file);
      });
      formData.append('isMeasurement', 'true');

      await WorthyAPI.uploadImage(itemId, formData);
    } catch (error) {
      window?.rollbar?.error(`uploadImage: ${error}`);
    }
  }
}

const sendQualifiedLeadPixel = (
  qualifiedLeadData: Record<string, any>,
  diamond_carat_weight = 0,
  unverifiedRegistration = false,
) => {
  if (
    !unverifiedRegistration &&
    qualifiedLeadData &&
    qualifiedLeadData.isQualified &&
    !qualifiedLeadData.isSuperExpensive &&
    diamond_carat_weight <= MAX_CARAT_FOR_GA
  ) {
    const estimation = qualifiedLeadData.estimationValue;
    const { postregScore } = qualifiedLeadData;

    GA.dataEvents.hqPurchase({
      postregScore: qualifiedLeadData.postregScore,
      estimationValue: qualifiedLeadData.estimationValue,
    });
    GA.dataEvents.ml4Purchase({ ml4ConversionValue: qualifiedLeadData.ml4ConversionValue });

    const leadValue =
      estimation *
      ((postregScore + postregMedian) / 2) *
      estimationToEvaluation *
      proceedToAuction *
      dealProbability *
      feesPercent;

    GA.dataEvents.firstQualifiedSubmission({
      projectedLeadValue: leadValue,
    });
  }
};

export const submitItem = createAsyncThunk<any, void, { state: RootState }>(
  `${SLICE_NAME}/submitItem`,
  async (a, { getState }) => {
    const state = getState().submit;

    WorthyAPI.storeStateInBackend(state, state.registration, 'SubmitItemBefore');
    let estimationResponse;
    const response = await WorthyAPI.createItem(state);
    const itemId = response?.data?.item?.id;
    if (itemId) {
      const isLoggedIn = state?.user?.isLoggedIn;
      GA.dataEvents.itemSubmited({ itemId: +itemId, itemType: state.type });
      GA.successfullItemSubmission();

      if (isLoggedIn) {
        const { qualifiedLeadData, item, user } = await generateEstimation(
          itemId,
          state.measureByImg,
        );

        if (user?.items_count === 1) {
          sendQualifiedLeadPixel(
            qualifiedLeadData,
            item?.properties?.diamond_carat_weight,
            user?.unverified_registration,
          );
        }
      } else {
        estimationResponse = await WorthyAPI.estimate(itemId).catch((error) => {
          window?.rollbar?.error(`estimate API failed: ${error}`);
        });
      }

      if (isLoggedIn) {
        await submitImages(state.photos, itemId);
      }
    }

    return {
      user: {
        ...response?.data?.user,
        ...(estimationResponse?.user || {}),
      },
      item: {
        ...response?.data?.item,
        ...(estimationResponse?.item || {}),
      },
    };
  },
);

export const getUser = createAsyncThunk(`${SLICE_NAME}/userData`, async () => {
  const response = await WorthyAPI.ADO();

  const userData = response.data.user;
  if (userData?.id) {
    GA.dataEvents.userIdentified({
      userId: userData?.id,
      userEmail: userData?.email,
      userFirstName: userData?.first_name,
      userLastName: userData?.last_name,
      userPhone: userData?.phone,
    });
  }

  return response.data;
});

export const postRegEstimation = createAsyncThunk<
  Record<string, any>,
  { userId: number },
  { state: RootState }
>(`${SLICE_NAME}/generateEstimation`, async ({ userId }, { getState }) => {
  const state = getState().submit;
  WorthyAPI.storeStateInBackend(state, state.registration, 'PostRegEstimationBefore');
  const itemType = state.type;
  const { itemId } = state.submitAPI;
  const response = await generateEstimation(itemId, state.measureByImg);
  _.set(response, 'user.id', userId);
  return response;
});

export const submissionStarted = createAsyncThunk<
  any,
  { submissionId: string; utmData: Record<string, any> },
  { state: RootState }
>(`${SLICE_NAME}/submissionStarted`, async ({ submissionId, utmData }, { getState }) => {
  const state = getState().submit;
  WorthyAPI.storeStateInBackend(state, null, 'SubmissionStarted', undefined, {
    submissionId,
    utmData,
  });

  return 'submissionStarted';
});

export const registerUser = createAsyncThunk<
  any,
  { data: SubmitState['registration']; clb: any; successClb?: any },
  { state: RootState }
>(
  `${SLICE_NAME}/registerUser`,
  async ({ data, clb, successClb }, { getState, rejectWithValue }) => {
    const state = getState().submit;
    const { bundleId, itemId } = state.submitAPI;

    WorthyAPI.storeStateInBackend(state, data, 'RegisterUserBefore');

    const response = await WorthyAPI.register(
      { ...data, activeFields: state.metaData.activeFields },
      bundleId,
    ).finally(clb);

    if (response?.data.errors) {
      return rejectWithValue(response?.data.errors);
    }

    await submitImages(state.photos, itemId);

    // should be done before the successClb
    const user = response?.data.user;
    if (user?.id) {
      GA.dataEvents.userIdentified({
        userId: +user.id,
        userFirstName: user.first_name,
        userLastName: user.last_name,
        userEmail: user.email,
        userPhone: user.phone,
      });
    }
    if (successClb) {
      successClb(response?.data);
    }
    return response?.data;
  },
);

export const gClientIdAcquired = createAsyncThunk<
  any,
  { submissionId: string; gClientId: string },
  { state: RootState }
>(`${SLICE_NAME}/gClientIdAcquired`, async ({ submissionId, gClientId }, { getState }) => {
  WorthyAPI.storegClientIdInBackend(submissionId, gClientId);

  return 'gClientIdAcquired';
});

export const updateUserPhoneNumber = createAsyncThunk<
  any,
  { data: SubmitState['registration']; callBack: any; successCallBack?: any },
  { state: RootState }
>(
  `${SLICE_NAME}/updatePhoneNumber`,
  async ({ data, callBack, successCallBack }, { getState, rejectWithValue }) => {
    const state = getState().submit;
    const { user } = state;
    WorthyAPI.storeStateInBackend(state, data, 'updateUserPhoneNumber');
    const response = await WorthyAPI.updatePhoneNumber(data, user?.id).finally(callBack);
    if (response.data.errors) {
      return rejectWithValue(response.data.errors);
    }
    if (successCallBack) {
      successCallBack();
    }
    return response.data;
  },
);

type WizardUseLabelPayload = {
  label: string;
  stepsGraph: LabeledDirectedGraph;
};

type EnterVertexByIdPayload = {
  vertexId: VertexId;
  stepsGraph: LabeledDirectedGraph;
};

function doWizardEnterVertexById(state: SubmitState, payload: EnterVertexByIdPayload) {
  const { stepsGraph } = payload;
  const { currentVertexId } = state.wizard;
  const currentVertex = currentVertexId ? stepsGraph.getVertex(currentVertexId) : null;
  const newVertexId = payload.vertexId;
  const newVertex = stepsGraph.getVertex(newVertexId);
  if (!newVertex) {
    throw new Error(`New vertex '${newVertexId}' does not exist.`);
  }

  if (currentVertexId === newVertexId) {
    return;
  }

  if (currentVertex) {
    currentVertex.onLeaveFns?.forEach((fn) => fn(state));
    if (currentVertexId) state.wizard.history.push(currentVertexId);
  }
  state.wizard.currentVertexId = newVertexId;
  newVertex.onEnterFns?.forEach((fn) => fn(state));
}

function wizardFindVertexByEdgeSkippingHidden(
  state: SubmitState,
  stepsGraph: LabeledDirectedGraph,
  fromVertexId: VertexId,
  label: string,
): VertexId {
  // we try to follow the edge with 'label' to get to the next vertex in the graph
  // if that vertex is hidden, we follow its 'next' edge recursively
  // until we find a visible vertex
  const vertex = stepsGraph.getVertex(fromVertexId);
  if (!vertex) {
    throw new Error(`Departure vertex '${fromVertexId}' does not exist.`);
  }
  const edge = vertex.edges.get(label);
  if (!edge) {
    throw new Error(`Edge '${label}' does not exist on vertex '${fromVertexId}'`);
  }
  const newVertexId = edge.destination;
  const newVertex = stepsGraph.getVertex(newVertexId);
  if (!newVertex) {
    throw new Error(`New vertex '${newVertexId}' does not exist.`);
  }
  const isHidden = newVertex.hiddenFns?.some((fn) => fn(state)) ?? false;
  if (!isHidden) {
    return newVertex.id;
  }
  return wizardFindVertexByEdgeSkippingHidden(state, stepsGraph, newVertexId, 'next');
}

function doWizardUseLabel(state: SubmitState, payload: WizardUseLabelPayload) {
  if (state.wizard.currentVertexId) {
    const currentVertex = payload.stepsGraph.getVertex(state.wizard.currentVertexId);
    if (!currentVertex) {
      throw new Error(`Current vertex ${state.wizard.currentVertexId} does not exist.`);
    }
    const edge = currentVertex.edges.get(payload.label);
    if (!edge) {
      throw new Error(`Edge '${payload.label}' does not exist on vertex '${currentVertex.id}'`);
    }
    const newVertexId = wizardFindVertexByEdgeSkippingHidden(
      state,
      payload.stepsGraph,
      state.wizard.currentVertexId,
      payload.label,
    );

    doWizardEnterVertexById(state, { vertexId: newVertexId, stepsGraph: payload.stepsGraph });
  } else {
    throw new Error('No current vertex id.');
  }
}

function doWizardHistoryBack(state: SubmitState) {
  if (state.wizard.history.length > 0) {
    const lastVertexId = state.wizard.history[state.wizard.history.length - 1];
    if (lastVertexId) {
      state.wizard.currentVertexId = lastVertexId;
      state.wizard.history = state.wizard.history.slice(0, state.wizard.history.length - 1);
    }
  }
}

function doWizardInitHistory(state: SubmitState) {
  state.wizard.history = [];
}

function doWizardFinish(state: SubmitState) {
  redirectToNewItem(state.submitAPI.itemId);
}

function doWizardRestart(state: SubmitState, payload: EnterVertexByIdPayload) {
  doWizardInitHistory(state);
  state.submitAPI.retryIsNeeded = false;
  doWizardEnterVertexById(state, payload);
}

export const submitSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    updateSubmitAPIForcePhone: (state, action: PayloadAction<boolean>) => {
      state.submitAPI.forcePhoneNumber = action.payload;
    },
    updateSubmitAPIItemId: (state, action: PayloadAction<string>) => {
      state.submitAPI.itemId = action.payload;
    },
    updateType: (state, action: PayloadAction<string>) => {
      state.type = action.payload;
    },
    updateSetting: (state, action: PayloadAction<string>) => {
      state.setting = action.payload;
    },
    updateShape: (state, action: PayloadAction<string>) => {
      state.shape = action.payload;
    },
    updateCondition: (state, action: PayloadAction<string>) => {
      state.condition = action.payload;
    },
    updateCustomBrand: (state, action: PayloadAction<string>) => {
      state.customBrand = action.payload;
    },
    updateModel: (state, action: PayloadAction<string>) => {
      state.model = action.payload;
    },
    updateBox: (state, action: PayloadAction<string>) => {
      state.box = action.payload;
    },
    updateDocuments: (state, action: PayloadAction<string>) => {
      state.papers = action.payload;
    },
    updateBandMaterial: (state, action: PayloadAction<string>) => {
      state.bandMaterial = action.payload;
    },
    updateCaseMaterial: (state, action: PayloadAction<string>) => {
      state.caseMaterial = action.payload;
    },
    updateGender: (state, action: PayloadAction<string>) => {
      state.gender = action.payload;
    },
    updateSecondarySubset: (state, action: PayloadAction<string>) => {
      state.secondarySubset = action.payload;
    },
    updateStyle: (state, action: PayloadAction<string>) => {
      state.style = action.payload;
    },
    updateMetalType: (state, action: PayloadAction<string>) => {
      state.metalType = action.payload;
    },
    updateDiamondNumber: (state, action: PayloadAction<number>) => {
      state.diamondNumber = action.payload;
    },
    updateTotalCarat: (state, action: PayloadAction<number>) => {
      state.totalCarat = action.payload;
    },
    updateCarat: (state, action: PayloadAction<number>) => {
      state.carat = action.payload;
    },
    updateCaratRangeTo: (state, action: PayloadAction<number>) => {
      state.caratRangeTo = action?.payload;
    },
    updateCaratClassification: (state, action: PayloadAction<CLASSIFICATION>) => {
      state.caratClassification = action?.payload;
    },
    updateColor: (state, action: PayloadAction<string>) => {
      state.color = action.payload;
    },
    updateClarity: (state, action: PayloadAction<string>) => {
      state.clarity = action.payload;
    },
    updateUser: (state, action: PayloadAction<any>) => {
      const userData = action.payload;
      const isLoggedIn = userData && (userData.type === 'user' ? userData.lead_id : userData.id);
      state.user = {
        ...userData,
        isLoggedIn: !!isLoggedIn,
        userData: {
          id: userData?.id,
          email: userData?.email,
          firstName: userData?.first_name,
          lastName: userData?.last_name,
          phone: userData?.phone,
        },
      };
    },
    updateCertificate: (state, action: PayloadAction<{ type: string; number: string }>) => {
      state.certificate = action.payload.type;
      state.certificateNumber = action.payload.number;
    },
    updateBrand: (state, action: PayloadAction<string>) => {
      state.brand = action.payload;
    },
    updatePremiumBrand: (state, action: PayloadAction<boolean>) => {
      state.premiumBrand = action.payload;
    },
    updateRegistration: (state, action: PayloadAction<SubmitState['registration']>) => {
      state.registration = action.payload;
    },
    updatePhotos: (state, action: PayloadAction<PhotoI[]>) => {
      state.photos = action.payload;
    },
    updateMeasureByImg: (state, action: PayloadAction<boolean>) => {
      state.measureByImg = action.payload;
    },
    updatePhotosOld: (state, action: PayloadAction<PhotoOldI[]>) => {
      state.photosOld = action.payload;
    },
    updateItemAutoRejected: (
      state,
      action: PayloadAction<{ rejected: boolean; reason: string }>,
    ) => {
      state.submitAPI.itemAutoRejected = action.payload.rejected;
      state.submitAPI.rejectReason = action.payload.reason;
      WorthyAPI.storeStateInBackend(state, state.registration, 'ItemAutoRejected');
    },
    updateWizardStepName: (state, action: PayloadAction<string>) => {
      state.wizard.stepName = action.payload;
    },
    addActiveField: (state, action: PayloadAction<string>) => {
      state.metaData.activeFields = [...state.metaData.activeFields, action.payload];
    },
    nextStep: (state, action: PayloadAction<Omit<IStep, 'comp'>[]>) => {
      const steps = action.payload;
      const nextStepIndex = nextVisibleStepIndex(
        steps,
        state.wizard.stepIndex,
        !!state.user.isLoggedIn,
        state.secondarySubset,
      );

      if (steps[nextStepIndex]) {
        state.wizard.stepName = steps[nextStepIndex].name;
      }

      if (
        nextStepIndex !== -1 &&
        nextVisibleStepIndex(
          steps,
          nextStepIndex,
          !!state.user.isLoggedIn,
          state.secondarySubset,
        ) === -1
      ) {
        state.wizard.isFinal = true;
      }
      if (nextStepIndex === -1 && state.submitAPI.itemId) {
        if (state.measureByImg || !state.submitAPI.itemAutoRejected) {
          redirectToNewItem(state.submitAPI.itemId);
        }
      } else {
        state.wizard.stepIndex = nextStepIndex;
      }
    },
    restartStep: (state, action: PayloadAction<Omit<IStep, 'comp'>[]>) => {
      const steps = action.payload;
      return {
        ...state,
        ...initialSubmitValues,
        wizard: {
          ...state.wizard,
          currentVertexId: null,
          stepName: '',
          stepIndex: 0,
          isFinal: false,
          loading: 0,
        },
        submitAPI: {
          ...initialState.submitAPI,
        },
      };
    },
    prevStep: (state, action: PayloadAction<Omit<IStep, 'comp'>[]>) => {
      const steps = action.payload;
      const prevStepIndex = prevVisibleStepIndex(
        steps,
        state.wizard.stepIndex,
        !!state.user.isLoggedIn,
        state.secondarySubset,
      );

      state.wizard.stepName = steps[prevStepIndex].name;

      if (prevStepIndex !== -1) {
        state.wizard.stepIndex = prevStepIndex;
      }
      state.wizard.isFinal = false;
    },
    wizardUseLabel: (state, action: PayloadAction<WizardUseLabelPayload>) => {
      doWizardUseLabel(state, action.payload);
    },
    wizardHistoryBack: (state) => {
      doWizardHistoryBack(state);
    },
    wizardInitHistory: (state) => {
      doWizardInitHistory(state);
    },
    wizardEnterVertexById: (state, action: PayloadAction<EnterVertexByIdPayload>) => {
      doWizardEnterVertexById(state, action.payload);
    },
    wizardFinish: (state) => {
      doWizardFinish(state);
    },
    wizardRestart: (state, action: PayloadAction<EnterVertexByIdPayload>) => {
      doWizardRestart(state, action.payload);
    },
    onReceivedGoogleCredential: (state, action: PayloadAction<string>) => {
      WorthyAPI.storeStateInBackend(state, null, 'ReceivedGoogleCredential', action.payload);
    },
    onRegisteredWithGoogle: (state, action: PayloadAction<any>) => {
      WorthyAPI.storeStateInBackend(state, action.payload, 'Registered/LoggedInWithGoogle');
    },
    addConversationMessage: (state, action: PayloadAction<Message>) => {
      const message: Message = {
        createdAtMs: action.payload.createdAtMs,
        content: action.payload.content,
        data: action.payload.data,
        role: action.payload.role,
      };

      const existMessage = state.conversation.messages.find(
        (m) => m.createdAtMs === action.payload.createdAtMs,
      );
      if (existMessage) return;

      state.conversation.messages = [...state.conversation.messages, message];

      const knownFunctions = ['submit-function'];
      if (action.payload.data === null || !knownFunctions.includes(action.payload.role)) return;

      state.conversation.itemData = action.payload.data;

      state.type = action.payload.data.itemType;

      state.setting = mapEventSettingStyleToStyles(action.payload.data.settingStyle);
      state.shape = mapEventShapeToShape(action.payload.data.shape);
      state.clarity = mapEventStoneClarityToClarity(action.payload.data.diamondClarity);

      state.caratClassification = mapEventStoneTypeToClassification(action.payload.data.stoneType);

      if (state.caratClassification !== CLASSIFICATION.GEMSTONE) {
        state.color = mapEventStoneColorToDiamondColor(action.payload.data.diamondColor);
      }

      const { certificateType, certificateNumber } = mapEventCertificateTypeToCertificate(
        action.payload.data.certificateType,
        action.payload.data.certificateNumber,
      );

      state.certificateNumber = certificateNumber;
      state.certificate = certificateType;

      state.box = action.payload.data.hasBox;
      state.papers = action.payload.data.hasPapers;
      state.model = action.payload.data.itemModel;

      const itemBrand = action.payload.data?.itemBrand;
      if (itemBrand && itemBrand !== 'Other') {
        state.brand = action.payload.data.itemBrand;
        state.premiumBrand = true;
      }

      const itemModel = action.payload.data?.itemModel;
      if (itemModel) {
        state.model = itemModel;
      }

      const itemBrandText = action.payload.data?.itemBrandText;
      if (itemBrand && itemBrand === 'Other' && itemBrandText) {
        state.brand = 'Other';
        state.customBrand = itemBrandText;
        state.model = state.type === 'watch' && state.model === '' ? 'Other' : state.model;
        state.premiumBrand = false;
      }

      const hasMainStone = action.payload.data?.hasMainStone;
      if (hasMainStone) {
        state.secondarySubset = 'diamond_center_stone';
        state.carat = action.payload.data.caratWeight || 0;
        state.caratRangeTo = action.payload.data.caratWeightTo || undefined;
      } else {
        state.secondarySubset = 'diamond_minor_stone_batch';
        state.diamondNumber = action.payload.data.numberOfStones || 0;
        state.totalCarat =
          action.payload.data.totalCaratWeight || action.payload.data.caratWeight || 0;
        state.style = 'Other';
      }

      state.metalType = action.payload.data.metalType;
      state.condition = action.payload.data.itemCondition;

      state.conversation.submit = true;
      state.conversation.waiting = false;
    },
    addChunkToConversationMessage: (
      state,
      action: PayloadAction<{ chunk: string; createdAtMs: number }>,
    ) => {
      const { chunk, createdAtMs } = action.payload;
      let message = state.conversation.messages.find((m) => m.createdAtMs === createdAtMs);

      if (message) {
        message.content += chunk;
      } else {
        message = {
          createdAtMs,
          data: null,
          content: chunk,
          role: 'assistant',
        };
        state.conversation.messages = [...state.conversation.messages, message];
        state.conversation.responseStarted = false;
      }
    },
    setConversationWaiting: (state, action: PayloadAction<{ waiting: boolean }>) => {
      state.conversation.waiting = action.payload.waiting;
    },
    setResponseStarted: (state, action: PayloadAction<{ responseStarted: boolean }>) => {
      state.conversation.responseStarted = action.payload.responseStarted;
    },
    interruptConversationSubmit: (state) => {
      state.conversation.submit = false;

      state.setting = '';
      state.shape = '';
      state.clarity = '';
      state.carat = 0;
      state.caratClassification = CLASSIFICATION.NATURAL;
      state.color = '';
      state.certificateNumber = '';
      state.certificate = CertificateType.None;
      state.box = '';
      state.papers = '';
      state.brand = '';
      state.premiumBrand = false;
      state.customBrand = '';
      state.model = '';
      state.secondarySubset = '';
      state.diamondNumber = 0;
      state.totalCarat = 0;
      state.style = '';
      state.metalType = '';
    },
  },

  extraReducers: (builder) => {
    builder.addCase('@@INIT', (state) => {
      const uvid = CookieStore.getUvid();
      const gaid = CookieStore.getGaId();
      const { submissionId } = state;

      const sendUserVars = () => {
        setTimeout(() => {
          if (typeof window.FullStory !== 'undefined' && window.FullStory.setUserVars) {
            window.FullStory.setUserVars({
              submissionId,
              uvid,
              gaid,
            });
          } else {
            sendUserVars();
          }
        }, 1000);
      };
      sendUserVars();
    });

    builder.addCase(submitItem.pending, (state) => {
      state.wizard.loading += 1;
      state.submitAPI.isError = false;
      state.submitAPI.errorMsg = '';
      state.photosOld = defaultPhotosOld;
    });
    builder.addCase(submitItem.fulfilled, (state, action) => {
      const itemId = action.payload.item.id;
      const { rejected, rejectReason } = isItemRejected(action.payload.item);
      state.submitAPI.itemAutoRejected = rejected;
      state.submitAPI.rejectReason = rejectReason;
      state.submitAPI.isError = false;
      state.submitAPI.errorMsg = '';
      state.submitAPI.itemId = itemId;
      state.submitAPI.defaultEstimation = action.payload.item.default_estimation;
      state.submitAPI.bundleId = action.payload.item.bundle.id;
      state.submitAPI.forcePhoto =
        !!action.payload.item.is_photo_mandatory ||
        !!action.payload.item?.estimation?.is_photo_mandatory;
      state.submitAPI.forcePhoneNumber = !!action.payload.item.forcePhoneField;
      state.submitAPI.displayOptionalPhoneLabel =
        !!action.payload.item.display_optional_phone_label;
      state.submitAPI.item = action.payload;
      state.submitAPI.err = action.payload.err;
      state.submitAPI.retryIsNeeded = false;

      state.wizard.loading -= 1;

      const isLoggedIn = state?.user?.isLoggedIn;
      WorthyAPI.storeStateInBackend(
        state,
        state.registration,
        rejected ? 'SubmitItemRejected' :                                 // eslint-disable-line
          isLoggedIn ? 'SubmitItemSuccessLoggedIn' : 'SubmitItemSuccess', // eslint-disable-line
      );
    });
    builder.addCase(submitItem.rejected, (state, action) => {
      state.submitAPI.isError = true;
      state.submitAPI.errorMsg = 'SUBMIT_ITEM_FAILED';
      state.submitAPI.err = action.error;

      state.wizard.loading -= 1;
      state.submitAPI.retryIsNeeded = true;
      WorthyAPI.storeStateInBackend(state, state.registration, 'SubmitItemFailure');
    });
    builder.addCase(registerUser.pending, (state) => {
      state.registerAPI.loading = true;
      state.registerAPI.isError = false;
      state.registerAPI.errorMsg = '';
    });
    builder.addCase(registerUser.rejected, (state, action) => {
      state.registerAPI.loading = false;
      state.registerAPI.isError = true;
      const errAlreadyTaken = JSON.stringify(action.payload || {}).includes('Already taken');
      state.registerAPI.errorMsg = errAlreadyTaken
        ? 'Email already taken. Please Log in instead'
        : 'We are experiencing some technical problems. please try again later.';
      state.registerAPI.err = action.error;
      if (!errAlreadyTaken)
        WorthyAPI.storeStateInBackend(state, state.registration, 'RegisterUserFailure');
    });
    builder.addCase(registerUser.fulfilled, (state, action) => {
      state.registerAPI.userData = action.payload.user;
      const userData = action.payload.user;
      state.user = {
        ...userData,
        userData: {
          id: userData?.id,
          email: userData?.email,
          firstName: userData?.first_name,
          lastName: userData?.last_name,
          phone: userData?.phone,
        },
        isLoggedIn: true,
      };
      registerToPromotion(userData?.email);
      WorthyAPI.storeStateInBackend(state, state.registration, 'RegisterUserSuccess');
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      const userData = action.payload.user;
      const isLoggedIn = userData && (userData.type === 'user' ? userData.lead_id : userData.id);
      if (userData) {
        state.user = {
          ...userData,
          userData: {
            id: userData?.id,
            email: userData?.email,
            firstName: userData?.first_name,
            lastName: userData?.last_name,
            phone: userData?.phone,
          },
          isLoggedIn: !!isLoggedIn,
        };
      }
    });
    builder.addCase(postRegEstimation.fulfilled, (state, action) => {
      const { item, qualifiedLeadData, user } = action.payload || {};
      WorthyAPI.storeStateInBackend(state, state.registration, 'PostRegEstimationSuccess', '', {
        qualifiedLeadData,
      });
      sendQualifiedLeadPixel(qualifiedLeadData, state.carat, user?.unverified_registration);
      const defaultValues = state.estimationAPI;
      state.estimationAPI = {
        estimationValue: qualifiedLeadData?.estimationValue,
        ml1Factor: qualifiedLeadData?.ml1Factor,
        valueCategory: item?.value_category || '',
        submissionQualification: item?.submission_qualification || QUALIFICATION.APPROVED,
        preregScore: qualifiedLeadData?.preregScore,
        ml4PreregScore:
          item?.estimation?.fb_ml4_prereg_score_weight || defaultValues.ml4PreregScore,
      };
    });
    builder.addCase(updateUserPhoneNumber.pending, (state) => {
      state.registerAPI.loading = true;
      state.registerAPI.isError = false;
      state.registerAPI.errorMsg = '';
    });
    builder.addCase(updateUserPhoneNumber.rejected, (state) => {
      state.registerAPI.loading = false;
      state.registerAPI.isError = true;
      state.registerAPI.errorMsg =
        'We are experiencing some technical problems. please try again later.';
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  updateType,
  updateSetting,
  updateShape,
  updateCarat,
  updateCaratRangeTo,
  updateColor,
  updateClarity,
  updateSecondarySubset,
  updateWizardStepName,
  updateStyle,
  updateMetalType,
  updateUser,
  updateDiamondNumber,
  updateTotalCarat,
  updateCertificate,
  updateBrand,
  updatePremiumBrand,
  updateRegistration,
  updateSubmitAPIItemId,
  updateSubmitAPIForcePhone,
  addActiveField,
  prevStep,
  nextStep,
  restartStep,
  updatePhotos,
  updatePhotosOld,
  updateMeasureByImg,
  onReceivedGoogleCredential,
  onRegisteredWithGoogle,
  updateItemAutoRejected,
  updateCondition,
  updateModel,
  updateBox,
  updateDocuments,
  updateBandMaterial,
  updateCaseMaterial,
  updateGender,
  updateCustomBrand,
  updateCaratClassification,
  addConversationMessage,
  addChunkToConversationMessage,
  interruptConversationSubmit,
  setConversationWaiting,
  setResponseStarted,
} = submitSlice.actions;

export const selectSubmitData = (state: RootState) => state.submit;
export const getConversationMessages = (state: RootState) => state.submit.conversation.messages;
export const getConversationWaiting = (state: RootState) => state.submit.conversation.waiting;
export const getConversationSubmit = (state: RootState) => state.submit.conversation.submit;
export const getResponseStarted = (state: RootState) => state.submit.conversation.responseStarted;
export const selectItemInputs = (state: RootState): ItemInputsTypes => state.submit;
export const selectType = (state: RootState) => state.submit.type;
export const selectItemType = (state: RootState) => {
  return state.submit.type ? state.submit.type[0].toUpperCase() + state.submit.type.slice(1) : '';
};
export const selectWizard = (state: RootState) => state.submit.wizard;
export const selectStyle = (state: RootState) => state.submit.style;
export const selectSecondarySubset = (state: RootState) => state.submit.secondarySubset;
export const selectShape = (state: RootState) => state.submit.shape;
export const selectCondition = (state: RootState) => state.submit.condition;
export const selectModel = (state: RootState) => state.submit.model;
export const selectBandMaterial = (state: RootState) => state.submit.bandMaterial;
export const selectCaseMaterial = (state: RootState) => state.submit.caseMaterial;
export const selectBox = (state: RootState) => state.submit.box;
export const selectDocuments = (state: RootState) => state.submit.papers;
export const selectGender = (state: RootState) => state.submit.gender;
export const selectColor = (state: RootState) => state.submit.color;
export const selectClarity = (state: RootState) => state.submit.clarity;
export const selectCaratClassification = (state: RootState) => state.submit.caratClassification;
export const selectBrand = (state: RootState) => state.submit.brand;
export const selectMetalType = (state: RootState) => state.submit.metalType;
export const selectCertificate = (state: RootState) => state.submit.certificate;
export const selectCertificateNumber = (state: RootState) => state.submit.certificateNumber;
export const selectRegisterAPI = (state: RootState) => state.submit.registerAPI;
export const selectSubmitAPI = (state: RootState) => state.submit.submitAPI;
export const selectUser = (state: RootState) => state.submit.user;
export const selectPhotos = (state: RootState) => state.submit.photos;
export const selectSetting = (state: RootState) => state.submit.setting;
export const selectPhotosOld = (state: RootState) => state.submit.photosOld;
export const selectRegister = (state: RootState) => state.submit.registration;
export const selectSubmissionId = (state: RootState) => state.submit.submissionId;
export const selectCarat = (state: RootState) => state.submit.carat;
export const selectFlow = (state: RootState) => state.submit.measureByImg;
export const selectValueCategory = (state: RootState) => state.submit.estimationAPI.valueCategory;
export const selectWizardHistoryLength = (state: RootState) => state.submit.wizard.history.length;
export const selectIsPremium = (state: RootState) => state.submit.premiumBrand;
export const selectCustomBrand = (state: RootState) => state.submit.customBrand;

export const selectIsLoggedIn = (state: RootState) => state.submit.user?.isLoggedIn;

export default submitSlice.reducer;

export const {
  wizardUseLabel,
  wizardEnterVertexById,
  wizardHistoryBack,
  wizardInitHistory,
  wizardFinish,
  wizardRestart,
} = submitSlice.actions;
export const selectCurrentVertexId = (state: RootState) => state.submit.wizard.currentVertexId;
