import React, { useEffect } from 'react';
import { IStep2, Wizard2 } from '../components/wizard';
import { useAppDispatch } from '../app/hooks';
import { updateType } from '../slices/submitSlice';
import CommonLayout from './layout/commonLayout';
import StepPhotoUpload from '../components/steps/common/step_photoUpload';
import StepRegistration from '../components/steps/common/step_registration';
import { loadGraph } from '../lib/wizard';
import StepReject from '../components/steps/common/step_reject';
import StepSubmit from '../components/steps/common/step_submit';
import Chat from '../components/steps/ai_submit_flow';
import GA from '../data/GA';
import '../styles/resetai.css';

const createSteps = (): IStep2[] => [
  {
    comp: Chat,
    name: 'ChatFlowSubmission',
    caption: '',
    title: 'Tell us about your ring',
    disableBackButton: true,
    staticProgressBar: true,
    disappearFromProgressBar: true,
    disableProgressBar: true,
    props: { fontSize: '42px', fontFamily: 'Playfair Display' },
    exits: {
      next: 'StepSubmit',
    },
  },
  {
    comp: StepSubmit,
    name: 'StepSubmit',
    caption: 'Submit',
    disableBackButton: true,
    exits: {
      next: 'StepRegistration',
      reject: 'StepReject',
    },
  },
  {
    comp: StepReject,
    name: 'StepReject',
    caption: 'Reject',
    disappearFromProgressBar: true,
    disableProgressBar: true,
    disableBackButton: true,
    exits: {
      restart: 'ChatFlowSubmission',
    },
  },
  {
    comp: StepRegistration,
    name: 'StepRegistration',
    caption: 'Register',
    itemRequired: true,
    skipOnlogin: true,
    disableBackButton: true,
  },
  {
    comp: StepPhotoUpload,
    name: 'StepPhotoUpload',
    caption: 'Upload photos',
    disappearFromProgressBar: true,
    disableProgressBar: true,
    disableBackButton: true,
    exits: {},
    final: true,
  },
];

function ChatPage() {
  const dispatch = useAppDispatch();
  const steps = createSteps();

  useEffect(() => {
    dispatch(updateType('ring'));
    GA.aiChatView();
  }, [dispatch]);

  const stepsGraph = loadGraph(steps);

  return (
    <CommonLayout hideCloseBtn>
      <Wizard2 stepsGraph={stepsGraph} />
    </CommonLayout>
  );
}

export default ChatPage;
