import * as React from 'react';
import { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
  getUser,
  selectSubmissionId,
  submissionStarted,
  gClientIdAcquired,
} from '../slices/submitSlice';
import { UTMDATA } from '../services/utmParams';
import EarringsPage from './earrings';
import NecklacePage from './necklace';
import RingPage from './ring';
import SubmissionPage from './submission';
import { BaseEvent } from '../data/events';
import LooseDiamondPage from './looseDiamond';
import WatchPage from './watch';
import BraceletPage from './bracelet';
import ChatPage from './chat';
import { EXPERIMENTS, isRingAIChatExperiment, variantIsLoaded } from '../lib/experiment';

const initGClientId = (gClientIdCallback: (gClientId: string) => void) => {
  const eventCallback = (event: Event) => {
    const { detail } = event as CustomEvent;
    console.log('gClientId', detail.gClientId);
    if (detail.gClientId) {
      gClientIdCallback(detail.gClientId);
    }
  };
  window.addEventListener('gClientId', eventCallback);
};

function IndexPage() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const submissionId = useAppSelector(selectSubmissionId);
  const isAiChat = isRingAIChatExperiment();

  BaseEvent.setSubmissionId(submissionId);

  useEffect(() => {
    const utmData = UTMDATA;
    dispatch(submissionStarted({ submissionId, utmData }) as any);
    initGClientId((gClientId) => {
      dispatch(gClientIdAcquired({ submissionId, gClientId }) as any);
    });
    variantIsLoaded(EXPERIMENTS.ringAIChat, ['0', '1']);
  }, [submissionId]);

  useEffect(() => {
    dispatch(getUser() as any);
  }, [dispatch]);

  if (isAiChat && location.pathname !== '/chat') {
    return <Navigate to="/chat" replace />;
  }

  return (
    <Routes>
      <Route index element={<SubmissionPage />} />
      <Route path="/ring" element={<RingPage />} />
      <Route path="/earrings" element={<EarringsPage />} />
      <Route path="/bracelet" element={<BraceletPage />} />
      <Route path="/necklace" element={<NecklacePage />} />
      <Route path="/loose_diamond" element={<LooseDiamondPage />} />
      <Route path="/watch" element={<WatchPage />} />
      <Route path="/chat" element={<ChatPage />} />
      <Route
        path="*"
        element={
          <main style={{ padding: '1rem' }}>
            <p>There&apos;s nothing here!</p>
          </main>
        }
      />
    </Routes>
  );
}

export default IndexPage;
