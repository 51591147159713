import {
  Box,
  Button,
  Icon,
  IconButton,
  Link,
  Paper,
  styled,
  TextField,
} from '@worthy-npm/worthy-common-ui-components';
import { motion } from 'framer-motion';
import React from 'react';

const StarInCircleIcon = Icon.createSvgIcon(
  <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="36" height="36" rx="18" stroke="#2C4EB5" strokeWidth="2" />
    <path
      d="M18.8672 9C19.9425 14.0074 23.862 17.9142 28.8729 18.9731L29 19L28.8729 19.0269C23.862 20.0858 19.9425 23.9926 18.8672 29L18.8399 28.8731C17.78 23.9371 13.9324 20.0765 9 19C13.9324 17.9235 17.78 14.0629 18.8399 9.12691L18.8672 9Z"
      stroke="#2C4EB5"
      strokeWidth="2"
    />
  </svg>,
  'StarInCircleIcon',
);

const FilledStarIcon = Icon.createSvgIcon(
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.8672 1C11.9425 6.00742 15.862 9.9142 20.8729 10.9731L21 11L20.8729 11.0269C15.862 12.0858 11.9425 15.9926 10.8672 21L10.8399 20.8731C9.78 15.9371 5.93237 12.0765 1 11C5.93237 9.92347 9.78 6.06287 10.8399 1.12691L10.8672 1Z"
      fill="#2C4EB5"
      stroke="#2C4EB5"
      strokeWidth="2"
    />
  </svg>,
  'FilledStarIcon',
);

const OutlinedStarIcon = Icon.createSvgIcon(
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.8672 1C11.9425 6.00742 15.862 9.9142 20.8729 10.9731L21 11L20.8729 11.0269C15.862 12.0858 11.9425 15.9926 10.8672 21L10.8399 20.8731C9.78 15.9371 5.93237 12.0765 1 11C5.93237 9.92347 9.78 6.06287 10.8399 1.12691L10.8672 1Z"
      fill="#F2F0EE"
      stroke="#2C4EB5"
      strokeWidth="2"
    />
  </svg>,
  'OutlinedStarIcon',
);

const ArrowR = Icon.createSvgIcon(
  <svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.54541 6.5H18M18 6.5C18 6.5 12.1667 4.31818 12.1667 0.5M18 6.5C18 6.5 12.1667 8.68182 12.1667 12.5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinejoin="bevel"
    />
  </svg>,
  'ArrowR',
);

const Header = styled(motion.div)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '16px',
  width: '100%',
  padding: '24px',
  boxSizing: 'border-box',
  textAlign: 'center',

  [theme.breakpoints.down('md')]: {
    paddingBottom: '14px',
  },
}));

const ChatArea = styled(Paper)({
  overflowY: 'auto',
  padding: 0,
  backgroundColor: 'transparent',
  boxShadow: 'none',
});

const InputContainerChat = styled(Box)({
  position: 'relative',
  width: '100%',
  borderRadius: '8px',
  boxSizing: 'border-box',
});

const ChatBoxContainer = styled(motion.div)(({ theme }) => ({
  backgroundColor: '#E5DEDA',
  borderRadius: '8px',
  padding: '14px 24px',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',

  width: '90%',
  maxWidth: '602px',
  height: '85vh',
  maxHeight: '100%',
  minHeight: '300px',
  marginTop: 'auto',
  justifyContent: 'flex-end',

  [theme.breakpoints.down('md')]: {
    backgroundColor: 'inherit',
    height: '100vh',
    maxHeight: '100%',
    padding: '0  0 12px 0',
  },
}));

const ContentWrapper = styled(Box)({
  flex: 1,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
});

const MessageBubble = styled(Box, {
  shouldForwardProp: (prop) => !['assistant', 'loading'].includes(prop as string),
})<{ assistant: boolean; loading: boolean }>(({ theme, assistant, loading }) => ({
  display: 'inline-block',
  padding: assistant ? '8px 0' : '8px 16px',
  borderRadius: '10px',
  marginBottom: '8px',
  backgroundColor: assistant ? 'transparent' : theme.palette.background.paper,
  maxWidth: '80%',
  wordWrap: 'break-word',
  whiteSpace: 'pre-wrap',
  '.ans-polish, #outlined-star': {
    animation: 'pulsate 1.5s ease-in-out infinite',
    opacity: 0.5,
    '@keyframes pulsate': {
      '0%': {
        opacity: 0.5,
      },
      '50%': {
        opacity: 1,
      },
      '100%': {
        opacity: 0.5,
      },
    },
  },
  ...(loading && {
    '.ans-polish, #outlined-star, #star': {
      animation: 'pulsate 1.5s ease-in-out infinite',
      opacity: 0.5,
      '@keyframes pulsate': {
        '0%': {
          opacity: 0.5,
        },
        '50%': {
          opacity: 1,
        },
        '100%': {
          opacity: 0.5,
        },
      },
    },
  }),

  [theme.breakpoints.down('md')]: {
    '#star': {
      display: 'none',
    },
  },
  [theme.breakpoints.up('md')]: {
    '#outlined-star': {
      display: 'none',
    },
  },
}));

const SendButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  bottom: '14px',
  right: '16px',
  minWidth: 'auto',
  width: '50px',
  height: '31px',
  borderRadius: '4px',
  backgroundColor: theme.palette.highlight.main,
  color: '#fff',
  fontWeight: 600,
  padding: 0,
  '&:hover': { backgroundColor: '#115293' },
}));

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => !['initInput'].includes(prop as string),
})<{ initInput: boolean }>(({ initInput }) => ({
  width: '100%',
  height: '100%',
  '& .MuiOutlinedInput-root': {
    paddingLeft: '16px',
    paddingRight: initInput ? '16px' : '65px',
    '& fieldset': { border: 'none' },
  },
}));

const MessageRow = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'role',
})<{ role: string }>(({ role }) => ({
  display: 'flex',
  justifyContent: role === 'user' ? 'flex-end' : 'flex-start',
}));

const OuterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#F2F0EE',
  alignItems: 'center',
  padding: '24px',
  boxSizing: 'border-box',
  height: '100%',
  gap: 'clamp(0px, 1vh, 80px)',
  [theme.breakpoints.down('md')]: {
    padding: 0,
  },
}));

const InitialInputContainer = styled(motion.div)(({ theme }) => ({
  height: '187px',
  backgroundColor: '#F2F0EE',
  borderRadius: '8px',
  padding: '16px',
  boxSizing: 'border-box',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  maxWidth: '586px',
  flexDirection: 'column',
  // marginTop: '80px',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const NextButton = styled(Button)({
  display: 'flex',
  width: '150px',
  height: '46px',
  borderRadius: '4px',
  padding: '12px 16px',
  margin: '16px auto 0',
  justifyContent: 'center',
});

const DisclaimerWrap = styled(Box)(({ theme }) => ({
  marginTop: 'auto',
  width: '100%',
  maxWidth: '964px',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  padding: '16px',
  borderRadius: '8px',
  backgroundColor: '#E5DEDA66',
  marginBottom: '16px',
  [theme.breakpoints.down('md')]: {
    width: '90%',
    paddingLeft: '32px',
  },
}));

const LinkStyle = styled(Link)(({ theme }) => ({
  color: theme.palette.secondary.main,
  textDecoration: 'none',
}));

const BtnWrapper = styled(motion.div)(({ theme }) => ({
  maxWidth: '555px',
  width: '100%',
  display: 'flex',
  marginTop: '34px',
  justifyContent: 'flex-end',

  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
  },
}));

const ContinueBtn = styled(Button)(({ theme }) => ({
  '&.Mui-disabled': {
    color: '#919AA7',
    backgroundColor: theme.palette.divider,
  },
}));

export {
  ContinueBtn,
  BtnWrapper,
  OutlinedStarIcon,
  FilledStarIcon,
  LinkStyle,
  Header,
  ArrowR,
  DisclaimerWrap,
  ContentWrapper,
  StarInCircleIcon,
  NextButton,
  OuterContainer,
  InitialInputContainer,
  MessageRow,
  ChatBoxContainer,
  ChatArea,
  MessageBubble,
  SendButton,
  StyledTextField,
  InputContainerChat,
};
