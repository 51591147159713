import Compressor from 'compressorjs';
import { CookieStore } from '../services/cookieStore';
import {
  AiItem,
  CertificateType,
  CLASSIFICATION,
  DiamondClarity,
  DiamondColor,
  DiamondShape,
  GemstoneColor,
  Styles,
} from '../data/ItemDetails';

export function isIframe() {
  return window.self !== window.top;
}

export const currentUrl = (): string => window.location.href;

export const isMobile = () => process.env.REACT_APP_DEVTYPE === 'mobile';
export const isTablet = () => process.env.REACT_APP_DEVTYPE === 'tablet';
export const isDesktop = () => process.env.REACT_APP_DEVTYPE === 'desktop';

export const parametrize = (value: string) => {
  return value
    .trim()
    .toLowerCase()
    .replace(/[^a-zA-Z0-9 -]/, '')
    .replace(/\s/g, '-');
};

// this fuction should be called exactly when the session is needed , the cookie need few seconds to be set
export const getSessionData = () => CookieStore.getSessionData();

export const toSnakeCase = (str: string): string => {
  return str.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
};

export const compress = (file: File | Blob): Promise<File | Blob> =>
  new Promise(
    (resolve, reject) =>
      new Compressor(file, {
        quality: 0.8,
        maxHeight: 4096,
        maxWidth: 4096,
        success: resolve,
        error: (error) => {
          console.info(`Error while compressing ${JSON.stringify(error)}`);
          resolve(file);
        },
      }),
  );

export const getItemType = () => {
  const path = window.location.pathname;
  if (path.startsWith('/ring')) return 'ring';
  if (path.startsWith('/earrings')) return 'earrings';
  if (path.startsWith('/bracelet')) return 'bracelet';
  if (path.startsWith('/necklace')) return 'necklace';
  if (path.startsWith('/loose_diamond')) return 'loose diamond';
  if (path.startsWith('/watch')) return 'watch';
  return '';
};

export const isPhotoMandatory = ({ forcePhoto, carat }: { forcePhoto: boolean; carat: number }) => {
  const itemType = getItemType();
  return itemType === 'watch' || forcePhoto || (itemType === 'ring' && carat === 0);
};

export function fileToDataUrl(
  file: any,
): Promise<{ src: string; id: string; url: string; type: string }> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      resolve({
        src: event.target.result,
        id: file.lastModified,
        url: event.target.result,
        type: 'img',
      });
    };
    reader.onerror = (err) => {
      reject(err);
    };
    reader.readAsDataURL(file);
  });
}

export const dataUrlToFile = (dataUrl: string): Promise<File> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', dataUrl, true);
    xhr.responseType = 'blob';

    xhr.onload = function () {
      if (this.status === 200) {
        const blob = this.response;

        let fileType = blob.type.split('/')[1];
        if (fileType === 'jpeg') fileType = 'jpg'; // jpeg -> jpg

        const file = new File([blob], `image.${fileType}`, { type: blob.type });
        resolve(file);
      } else {
        console.error('Error while converting dataUrl to file');
      }
    };

    xhr.onerror = function () {
      console.error('Error while converting dataUrl to file');
    };

    xhr.send();
  });
};

export const toCamelCase = (str: string) => {
  return str
    .split(' ')
    .map((word: string, idx: number) =>
      idx === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
    )
    .join('');
};

export const toTitleCase = (str: string) => {
  return str
    .split(' ')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

export const mapEventStoneTypeToClassification = (
  stoneType: AiItem['stoneType'],
): CLASSIFICATION => {
  // looks like wrong but we have this value by default, keep for now
  if (!stoneType) return CLASSIFICATION.NATURAL;

  switch (stoneType) {
    case 'Natural':
      return CLASSIFICATION.NATURAL;
    case 'Lab-grown':
      return CLASSIFICATION.LAB_GROWN;
    case 'Gemstone':
      return CLASSIFICATION.GEMSTONE;
    default:
      console.error(`Invalid stone type: ${stoneType}`);
      throw new Error(`Invalid stone type: ${stoneType}`);
  }
};

export const mapEventShapeToShape = (
  shape: AiItem['shape'],
): (typeof DiamondShape)[keyof typeof DiamondShape] => {
  return shape.toLowerCase();
};

export const mapEventSettingStyleToStyles = (
  value: AiItem['settingStyle'],
): (typeof Styles)[keyof typeof Styles] => {
  if (value === '3 Stones') return '3 stone';

  return value.toLowerCase();
};

export const mapEventStoneColorToDiamondColor = (
  value: AiItem['stoneColor'],
): (typeof DiamondColor)[keyof typeof DiamondColor] => {
  if (!value) return '';

  const color = Object.values(DiamondColor).find((c) => c === value.toLowerCase());
  if (color) return color;

  return DiamondColor.NotSure;
  // const msg = `Invalid diamond color: ${value}`;
  // console.error(msg);
  // throw new Error(msg);
};

export const mapEventStoneColorToGemstoneColor = (
  value: AiItem['stoneColor'],
): (typeof GemstoneColor)[keyof typeof GemstoneColor] => {
  if (!value) return '';

  const color = Object.values(GemstoneColor).find((c) => c === value.toLowerCase());
  if (color) return color;

  return GemstoneColor.NA;
  // const msg = `Invalid gemstone color: ${value}`;
  // console.error(msg);
  // throw new Error(msg);
};

export const mapEventStoneClarityToClarity = (
  value: AiItem['stoneClarity'],
): (typeof DiamondClarity)[keyof typeof DiamondClarity] => {
  if (!value) return '';

  const clarity = Object.values(DiamondClarity).find((c) => c === value.toLowerCase());
  if (clarity) return clarity;

  return DiamondClarity.NotSure;

  // const msg = `Invalid diamond clarity: ${value}`;
  // console.error(msg);
  // throw new Error(msg);
};

export const mapEventCertificateTypeToCertificate = (
  certificateType: AiItem['certificateType'],
  certificateNumber: string,
): {
  certificateType: (typeof CertificateType)[keyof typeof CertificateType];
  certificateNumber: string;
} => {
  if (certificateNumber && !certificateType)
    return { certificateType: CertificateType.Other, certificateNumber };
  if (!certificateType) return { certificateType: CertificateType.None, certificateNumber: '' };

  const certificate = Object.values(CertificateType).find(
    (c) => c === certificateType.toLowerCase(),
  );
  if (certificate) return { certificateType, certificateNumber };
  if (certificateNumber) {
    return { certificateType: CertificateType.None, certificateNumber };
  }

  const msg = `Invalid certificate type: ${certificateType}`;
  console.error(msg);
  throw new Error(msg);
};
