import { io, Socket } from 'socket.io-client';
import { CookieStore } from './cookieStore';
import {
  addChunkToConversationMessage,
  addConversationMessage,
  setConversationWaiting,
  Message,
} from '../slices/submitSlice';
import { AppDispatch } from '../app/store';

const backendUrl = process.env.REACT_APP_SUBMIT_BE_URL;

if (!backendUrl) {
  throw new Error('REACT_APP_SUBMIT_BE_URL is not set');
}

let socket: Socket | null = null;
const subscribedEvents = new Set<string>();

export const initSocket = (submissionId: string): Socket => {
  if (socket) {
    console.warn('Socket already initialized.');
    return socket;
  }

  const ga = CookieStore.getGaId();

  socket = io(backendUrl, {
    reconnectionAttempts: 20,
    query: { submissionId, ga },
  });

  socket.io.on('error', (error) => {
    console.error('IO Error:', error);
  });

  socket.io.on('reconnect', (attempt) => {
    console.log(`IO Reconnected after ${attempt} attempts`);
  });

  socket.on('connect', () => {
    console.log('IO Connected to socket server');
  });

  socket.on('disconnect', (reason) => {
    console.log('IO Disconnected from socket server:', reason);
  });

  // socket.onAny((event, ...args) => {
  //   console.log(`Received event: ${event}`, args);
  // });

  return socket;
};

export const getSocket = (): Socket | null => {
  if (!socket) {
    console.warn('Socket is not initialized. Call initSocket first.');
  }
  return socket;
};

export const closeSocket = () => {
  if (socket) {
    socket.disconnect();
    socket = null;
    console.log('Socket closed');
  }
};

export const subscribeToSocketEvents = (submissionId: string, dispatch: AppDispatch) => {
  console.log('Subscribing to socket events');

  initSocket(submissionId);
  if (!socket) throw new Error('Socket is not initialized');

  if (!subscribedEvents.has('NEW_MESSAGE')) {
    socket.on('NEW_MESSAGE', (payload: Message) => {
      dispatch(addConversationMessage(payload));
    });
    subscribedEvents.add('NEW_MESSAGE');
  }

  if (!subscribedEvents.has('CHUNK_OF_MESSAGE')) {
    socket.on('CHUNK_OF_MESSAGE', (payload: { chunk: string; createdAtMs: number }) => {
      dispatch(addChunkToConversationMessage(payload));
    });
    subscribedEvents.add('CHUNK_OF_MESSAGE');
  }

  if (!subscribedEvents.has('ASSISTANT_FINISHED')) {
    socket.on('ASSISTANT_FINISHED', () => {
      dispatch(setConversationWaiting({ waiting: false }));
    });
    subscribedEvents.add('ASSISTANT_FINISHED');
  }
};

export default getSocket;
