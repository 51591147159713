import { CookieStore } from '../services/cookieStore';
import GA from '../data/GA';

const EXPERIMENT_SEPARATOR = '||';
const VARIANT_SEPARATOR = '::';

interface Experiment {
  name: string;
  variants: string[];
}

export const EXPERIMENTS: { [key: string]: Experiment } = {
  ringSetting: {
    name: 'ring_setting',
    variants: ['control_ring_with_setting_step', 'var_1_ring_without_setting_step'],
  },
  ringAIChat: {
    name: 'ring_ai_chat',
    variants: ['control_ring_ai_chat', 'var_1_ring_ai_chat'],
  },
};
function getExperimentCookieValue(experimentName: string) {
  // Cookie format: _wor_opt_exp_=experiment1±variant1§experiment2±variant2§experiment3±variant3
  const experimentsCookie: string = CookieStore.getExperiments();
  const experimentStrings = experimentsCookie.split(EXPERIMENT_SEPARATOR);
  const experiments = experimentStrings.map((experimentString: string) =>
    experimentString.split(VARIANT_SEPARATOR),
  );
  const exp = experiments.find((experiment) => experiment[0] === experimentName);
  return exp ? exp[1] : undefined;
}

export function getExperiment(
  experimentName: string,
  defaultValue: string | undefined = undefined,
) {
  console.debug(`experiment ${experimentName} defaultValue: `, defaultValue);
  const experimentValue = getExperimentCookieValue(experimentName) || defaultValue;

  console.debug(`experiment ${experimentName} returning: `, experimentValue);
  return experimentValue;
}

export const getVariant = (experiment: Experiment) => {
  const variant = getExperimentCookieValue(experiment.name);
  if (!variant) {
    return '';
  }
  const variantIndex = experiment.variants.indexOf(variant);
  if (variantIndex === -1) {
    console.log(variant, 'is not a valid variant for experiment', experiment.name);
    return '';
  }
  return `${variantIndex}`;
};

export const variantIsLoaded = (experiment: Experiment, variantIndexes: string[]) => {
  const variantFromCookie = getVariant(experiment);
  const variantIndex = variantIndexes.indexOf(variantFromCookie);
  if (variantIndex === -1) {
    console.log(
      `__variants:${variantIndexes
        .map((i) => experiment.variants[+i])
        .filter((v) => v !== undefined)} loaded without cookie variant`,
    );
    return;
  }
  GA.experimentEvent(experiment.name, experiment.variants[+variantFromCookie]);
};

export function isPromoExperiment() {
  return false;
}

export function isRingWithoutSettingExperiment() {
  return (
    getExperimentCookieValue(EXPERIMENTS.ringSetting.name) === EXPERIMENTS.ringSetting.variants[1]
  );
}

export function isRingAIChatExperiment() {
  return (
    getExperimentCookieValue(EXPERIMENTS.ringAIChat.name) === EXPERIMENTS.ringAIChat.variants[1]
  );
}

export function inSellerDashboardExperiment() {
  const experimentValue = getExperiment('seller_dashboard');
  return experimentValue?.toLowerCase().startsWith('var_');
}
