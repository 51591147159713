import _ from 'lodash';

const { REACT_APP_GIT_HASH } = process.env;

const EVENTS = {
  names: {
    interaction: 'interaction',
    data: 'pushData',
    feedback: 'feedback',
    experiment: 'experimentParticipation',
  },
  actions: {
    open: 'open',
    click: 'click',
    success: 'success',
    select: 'select',
    input: 'input',
    navigate: 'navigate',
    focus: 'focus',
    clear: 'clear',
    toggle: 'toggle',
    failure: 'failure',
    close: 'close',
  },
  domains: {
    submissionFlow: 'submissionFlow',
    registration: 'registration',
    photoUpload: 'photoUpload',
    submitItem: 'submitItem',
  },
  navigationObjects: {
    intercom: 'intercom',
    back: 'back',
    next: 'next',
    skip: 'skip',
    continue: 'continue',
    tooltip: 'tooltip',
    ctaSubmission: 'ctaSubmission',
    cashForGold: 'cashForGold',
    submissionAiConfirmItemInfoNextBtn: 'submissionAiConfirmItemInfoNextBtn',
    aiTextInputFocus: 'aiTextInputFocus',
  },
} as const;

declare global {
  interface Window {
    dataLayer: any;
  }
}

interface KeyVal {
  [key: string]: any;
}

const removeUndefined = (obj: KeyVal) => {
  // eslint-disable-next-line no-param-reassign
  Object.keys(obj).forEach((key) => ([null, undefined].includes(obj[key]) ? delete obj[key] : {}));
  return obj;
};

export const getDataLayer = () => {
  return window.dataLayer;
};

export const pushToDataLayer = (data: any) => {
  if (_.isEmpty(data)) return;

  if (!window.dataLayer) return;

  window.dataLayer.push(removeUndefined(data));
};

export class BaseEvent {
  // should be set when the app start
  static submissionId: string;

  static dataObject: Record<string, any> = {};

  static pushToDataLayer = (data: Record<string, any>) => {
    if (_.isEmpty(data)) return;

    if (!window.dataLayer) return;

    window.dataLayer.push(data);
  };

  static setToDataObject = (obj: Record<string, any>) => {
    this.dataObject = {
      ...this.dataObject,
      ...(obj || {}),
    };
  };

  static keysMissingFromDataObj = (keys: string[]) => {
    return _.difference(keys, _.keys(this.dataObject));
  };

  static setSubmissionId = (id: string) => {
    this.submissionId = id;
  };

  static locationObject = (location: string[]) => {
    const [
      location1 = null,
      location2 = null,
      location3 = null,
      location4 = null,
      location5 = null,
    ] = location;
    return {
      location1,
      location2,
      location3,
      location4,
      location5,
    };
  };

  static eventObject = (
    eventName: (typeof EVENTS.names)[keyof typeof EVENTS.names],
    action: (typeof EVENTS.actions)[keyof typeof EVENTS.actions],
    location: string[],
    actionedObject?: string,
    actionedValue?: any,
    actionedObject2?: string,
    actionedValue2?: any,
    actionedObject3?: string,
    actionedValue3?: any,
    actionedObject4?: string,
    actionedValue4?: any,
  ) => ({
    event: eventName,
    action: action || null,
    actionedObject: actionedObject || null,
    actionedValue: actionedValue || null,
    actionedObject2: actionedObject2 || null,
    actionedValue2: actionedValue2 || null,
    actionedObject3: actionedObject3 || null,
    actionedValue3: actionedValue3 || null,
    actionedObject4: actionedObject4 || null,
    actionedValue4: actionedValue4 || null,
    fieldName: 'submissionId',
    fieldValue: this.submissionId || null,
    appVersion: REACT_APP_GIT_HASH || null,
    ...this.locationObject(location),
  });

  static dataEventObject = (data: any) =>
    removeUndefined({
      ...data,
      event: EVENTS.names.data,
      appVersion: REACT_APP_GIT_HASH,
      submissionId: this.submissionId,
    });

  static data(data: object) {
    this.setToDataObject(removeUndefined(data));
    this.pushToDataLayer(this.dataEventObject(data));
  }

  static open(
    location: string[],
    actionedObject?: string,
    actionedValue?: string,
    actionedObject2?: string,
    actionedValue2?: string,
    actionedObject3?: string,
    actionedValue3?: string,
  ) {
    this.pushToDataLayer(
      this.eventObject(
        EVENTS.names.feedback,
        EVENTS.actions.open,
        location,
        actionedObject,
        actionedValue,
        actionedObject2,
        actionedValue2,
        actionedObject3,
        actionedValue3,
      ),
    );
  }

  static click(location: string[], actionedObject?: string, actionedValue?: any) {
    this.pushToDataLayer(
      this.eventObject(
        EVENTS.names.interaction,
        EVENTS.actions.click,
        location,
        actionedObject,
        actionedValue,
      ),
    );
  }

  static success(
    location: string[],
    actionedObject?: string,
    actionedValue?: string,
    actionedObject2?: string,
    actionedValue2?: string,
    actionedObject3?: string,
    actionedValue3?: string,
    actionedObject4?: string,
    actionedValue4?: any,
  ) {
    this.pushToDataLayer(
      this.eventObject(
        EVENTS.names.feedback,
        EVENTS.actions.success,
        location,
        actionedObject,
        actionedValue,
        actionedObject2,
        actionedValue2,
        actionedObject3,
        actionedValue3,
        actionedObject4,
        actionedValue4,
      ),
    );
  }

  static select(
    location: string[],
    actionedObject: string,
    actionedValue: any,
    actionedObject2?: string,
    actionedValue2?: any,
    actionedObject3?: string,
    actionedValue3?: any,
    actionedObject4?: string,
    actionedValue4?: any,
  ) {
    this.pushToDataLayer(
      this.eventObject(
        EVENTS.names.interaction,
        EVENTS.actions.select,
        location,
        actionedObject,
        actionedValue,
        actionedObject2,
        actionedValue2,
        actionedObject3,
        actionedValue3,
        actionedObject4,
        actionedValue4,
      ),
    );
  }

  static navigate(
    location: string[],
    actionedObject: (typeof EVENTS.navigationObjects)[keyof typeof EVENTS.navigationObjects],
    actionedValue?: string,
    actionedObject2?: string,
    actionedValue2?: string,
    actionedObject3?: string,
    actionedValue3?: string,
  ) {
    this.pushToDataLayer(
      this.eventObject(
        EVENTS.names.interaction,
        EVENTS.actions.navigate,
        location,
        actionedObject,
        actionedValue,
        actionedObject2,
        actionedValue2,
        actionedObject3,
        actionedValue3,
      ),
    );
  }

  static focus(
    location: string[],
    actionedObject: (typeof EVENTS.navigationObjects)[keyof typeof EVENTS.navigationObjects],
    actionedValue?: string,
    actionedObject2?: string,
    actionedValue2?: string,
    actionedObject3?: string,
    actionedValue3?: string,
  ) {
    this.pushToDataLayer(
      this.eventObject(
        EVENTS.names.interaction,
        EVENTS.actions.focus,
        location,
        actionedObject,
        actionedValue,
        actionedObject2,
        actionedValue2,
        actionedObject3,
        actionedValue3,
      ),
    );
  }

  static input(
    location: string[],
    actionedObject: string,
    actionedValue: any,
    actionedObject2?: string,
    actionedValue2?: any,
  ) {
    this.pushToDataLayer(
      this.eventObject(
        EVENTS.names.interaction,
        EVENTS.actions.input,
        location,
        actionedObject,
        actionedValue,
        actionedObject2,
        actionedValue2,
      ),
    );
  }

  static experiment({
    experimentName,
    variantName,
  }: {
    experimentName: string;
    variantName: string;
  }) {
    this.pushToDataLayer({
      event: EVENTS.names.experiment,
      experimentName,
      variantName,
    });
  }
}
