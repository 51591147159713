import { Cookies } from 'react-cookie';

const EXPERIMENTS_COOKIE_NAME = '_wor_exp';

const cookie = new Cookies();

/* eslint-disable import/prefer-default-export */
export class CookieStore {
  static getSessionData = () => cookie.get('__utmsession') || {};

  static getReferer = () => cookie.get('worthy_referer') || null;

  static getUvid = () => cookie.get('uvid') || null;

  static getGaId = () => cookie.get('_ga') || null;

  static getRefererFriendbuy = () => cookie.get('friendbuy_referer') || null;

  static getUploadPhotoExp = () => cookie.get('opt_submission_upload_photo') || null;

  static getMandatoryPhoneLabel = () => cookie.get('mandatory_phone_label') || null;

  static getGoogleSigninExp = () => cookie.get('opt_submission_gsignin') === 'true';

  static getVerifyPhoneNumberExp = () => cookie.get('opt_submission_verification_step') === 'true';

  static getGoogleButtonOnTopExp = () => {
    return cookie.get('opt_submission_google_button_on_top') === 'true';
  };

  static getExperiments = () => cookie.get(EXPERIMENTS_COOKIE_NAME) || '';

  static getOptionalPhoneLabelExp = () => cookie.get('optional_phone_label') || null;
}
